import React from 'react'
import Layout from '../components/layout-default'
import { Link } from 'gatsby'
import Breadcrumbs from "../components/breadcrumbs";

const crumbs = [
  {
    label: "Home",
    link: "",
  },
  {
    label: "Confirmation",
  },
];
class Confirmation extends React.Component {

  redirectHome () {
    setTimeout(()=> {
      window.location.href = "/"
    }, 5000)
  }

  componentDidMount() {
    this.redirectHome()
  }
  

  render() {
    return (
      <Layout
        title="Confirmation | RF Dance" 
        pathname="confirmation"
        robots="noindex"
        noHero="no-hero">
        <div className="container" style={({'textAlign':'center','margin':'5em auto 22em'})}>
          <Breadcrumbs crumbs={crumbs} />
          
          <h1>Thanks for purchasing!</h1>
          <p>We'll redirect you to the homepage in a few seconds.</p>
          <Link to="/">Or, go there now</Link>
        </div>
      </Layout>
    )
  }
} 

export default Confirmation